import styled from '@emotion/styled'

import { TEST_IDS } from '@components/button/test/constants'
import Spinner from 'microcomponents/spinner'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export default function SpinnerComponent() {
  return (
    <Container data-e2eid={TEST_IDS.SPINNER}>
      <Spinner />
    </Container>
  )
}
